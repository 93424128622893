

 nav {
  width: min(100%, 13000px);

   /* max-width: 900px; */
   /* height: 50px; */
   /* border: 2px solid rgb(40, 255, 62); */
 }

 ol {
   list-style-type: none;

   display: flex;
   justify-content: space-evenly;
   /* border: 2px solid rgb(10, 47, 255); */
   margin: 5px;
   height: 50px;
   padding: 0px;
 }
 

 .special-button {
  /* margin: auto; */
   border-radius: 50px;
   font-size: 22px;
   padding: 0px;
   width: 40px;
   height: 40px;

    text-align: center;
   background: rgb(225, 224, 222);
   box-shadow: 10px 10px 12px rgb(188,186,182),
     -6px -6px 12px rgb(254, 253, 250);
     border:none;
   /* border: 1.5px solid rgb(255, 0, 0); */
   cursor:pointer;
 }

 .special-button.selected{

  box-shadow: inset 4px 4px 10px rgb(161, 159, 156),inset -4px -4px 10px rgb(254, 253, 250);
  /* background: rgb(255, 0, 0); */
 }




 li button:focus {
  border:none;
   box-shadow: inset 10px 10px 12px rgb(188, 186, 182),
     inset -6px -6px 12px rgb(254, 253, 250);
 }

 li:hover {
  transform:scale(1.05);
 }



