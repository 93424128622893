

section button {
  /* display: inline-block; */
  border-radius: 10px;
  color: rgb(43, 43, 43);
  font-family: Calibri;
  /* text-align: center; */
  font-size: min(2.5vh, 19px);
  padding: 8px;
  width: min(45%, 120px);
  height: 40px;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: inherit;
  border: none;
  position: relative;
  /* overflow: hidden; */
  z-index: 1;
  border-radius: 30px;
  background: rgb(225, 224, 222);
  box-shadow: 15px 15px 25px rgb(188, 186, 182),
             -10px -10px 25px rgb(254, 253, 250);
  cursor:pointer;
}
section button::after {
  content: '';
  width: 0;
  height: 40px;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgba(247, 201, 48, 0.673) 0%, rgb(247, 128, 48) 100%);
  transition: .5s ease;
  display: block;
  z-index: -1;
 }
 section button:focus::after {
  animation: couleur 1s ease forwards;
}

@keyframes couleur {
  40% {
    width: 100%;
  }80% {
    width: 100%;
  }100% {
    width: 0px;
  }
}



section button:hover{
  box-shadow: inset 6px 6px 12px rgb(188, 186, 182),
              inset -4px -4px 12px rgb(254, 253, 250);
 }

 button[disabled] {
  opacity: 0.5; /* Réduire l'opacité pour donner une apparence de désactivation */
  cursor: not-allowed; /* Changer le curseur pour indiquer qu'il est non cliquable */
  /* Ajoutez d'autres styles selon vos besoins pour le bouton désactivé */
}