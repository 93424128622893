

/* Personnaliser la barre de défilement pour Firefox */
* {
  scrollbar-color: #ffffff #ffffff00; /* Couleur de la barre de défilement et de la piste */
  scrollbar-width: thin; /* Largeur de la barre de défilement */
}

/* Personnaliser l'icône de la barre de défilement */
*::-moz-scrollbar-thumb {
  background-color: #ffffff; /* Couleur de l'icône de la barre de défilement */
  border-radius: 3px; /* Rayon de la bordure de l'icône de la barre de défilement */
}

/* Personnaliser la bordure de la barre de défilement */
*::-moz-scrollbar-track {
  background-color: #ffffff00; /* Couleur de la bordure de la barre de défilement */
  border-radius: 3px; /* Rayon de la bordure de la barre de défilement */
}

/* Personnaliser la barre de défilement pour les navigateurs WebKit (Safari, Chrome, etc.) */
::-webkit-scrollbar {
  width: 6px; /* Largeur de la barre de défilement */
  height: 6px; /* Hauteur de la barre de défilement */
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* Couleur de l'icône de la barre de défilement */
  border-radius: 3px; /* Rayon de la bordure de l'icône de la barre de défilement */
}

::-webkit-scrollbar-track {
  background-color: #ffffff00; /* Couleur de la bordure de la barre de défilement */
  border-radius: 3px; /* Rayon de la bordure de la barre de défilement */
}





