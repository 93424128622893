header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(239, 237, 227);
  padding-bottom: 0px;

}

/* titre_______________________________________________________________________________________________________________________ */

header div {
  color: rgb(36, 36, 36);
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  margin: min(1vw, 5px);
  margin-left: 3vw;
  align-items: flex-start;
  font-family: Calibri;
  /* border: 2px solid rgb(51, 255, 0); */
  cursor:pointer;
}

header h1 {
  width: 100%;
  font-size: min(6vw, 30px);
  /* border: 2px solid rgb(0, 26, 255); */
  margin: 0px;
}


header h2 {
  width: 100%;
  text-align: end;
  margin: 0px;
  color: rgb(0, 0, 0);
  margin-top: -5px;
  font-size: min(2.6vw, 13px);
  font-family:Caveat;

}

b {
  /* background: rgba(28, 27, 34,1); */
  color: rgb(255, 132, 50);
  margin: auto;
}

header img {
  height:min(9vw, 50px);
  /* object-fit: contain; */
  margin: 5px;
  margin-right: 3vw;
  cursor:pointer;
}