
#sectionParametre{
  width: 90%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid rgb(255, 0, 0);
 }

#parametreList{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* border: 2px solid rgb(43, 0, 255); */
  margin-bottom: 15px;
  justify-content:flex-start;
 align-items: center;
}










#listComposant{
  width: 95%;
  height: 40vh;
  min-height: 210px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
 align-items: center;
/* border: 2px solid rgb(255, 251, 0); */
overflow-y: scroll;
overflow-x: hidden;


}





#parametreLigne{
  flex: 1 0 300px;
  /* width: 90%; */
  padding: 8px;
  display: flex;
  align-content: center;
max-width: 450px;
  /* border: 2px solid rgb(21, 255, 0); */
  /* border-right: 1px solid rgb(255, 255, 255); */
}

#parametreInput{
  display: flex;
  justify-content: flex-end;
  align-items:center;
  flex-wrap: nowrap;

  /* width: 100px; min(70%, 200px); */
  margin-bottom: 14p; 
  padding: 6px;
  font-size: 15px;
  color: rgb(19, 59, 56);
  border-radius: 10px;
  border:none;
  background: rgb(243, 241, 236);
  box-shadow: inset 3px 5px 25px rgb(165, 163, 160),
    inset -10px -8px 15px rgb(240, 236, 236);

    /* border: 2px solid rgb(19, 106, 11); */
}




#sectionParametre label {
  /* width: min(70%, 280px);
   color: rgb(36, 36, 36);
   font-size: 15px;
   padding: 6px;
   padding-top: 0px;
   padding-bottom: 0px;
   margin: 5px;
   text-align: start; */
   width: 70%;
   /* border: 0.1px solid rgba(0, 0, 0, 0.301); */
   cursor: help;
 }

 #sectionParametre input {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
 
  /* -webkit-appearance: none;  */
  /* width: min(70%, 280px);
  margin-bottom: 14px; 
  padding: 6px;
  font-size: 15px;
  color: rgb(19, 59, 56);
  border-radius: 10px;
  border:none;
  background: rgb(243, 241, 236);
  box-shadow: inset 3px 5px 25px rgb(165, 163, 160),
    inset -10px -8px 15px rgb(240, 236, 236); */
    text-align: right;
    width: 100px;
  height: 20px;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
  border:none;
  background: rgba(243, 241, 236, 0);
  box-shadow: inset 3px 5px 25px rgba(165, 163, 160, 0),
    inset -10px -8px 15px rgba(240, 236, 236, 0);
    /* border: 2px  solid rgb(255, 0, 0); */
}


#sectionParametre input[disabled] {
  opacity: 0.5; /* Réduire l'opacité pour donner une apparence de désactivation */
  cursor: not-allowed; /* Changer le curseur pour indiquer qu'il est non cliquable */
  /* Ajoutez d'autres styles selon vos besoins pour le bouton désactivé */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#sectionParametre select {
  /* width: min(76%, 284px);
  margin-bottom: 14px;
  padding: 6px;
  font-size: 15px;
  color: rgb(19, 59, 56);
  border-radius: 10px;
  border:none;
  background: rgb(243, 241, 236);
  box-shadow: inset 3px 5px 25px rgb(165, 163, 160),
    inset -10px -8px 15px rgb(240, 236, 236); */
    width: 145px;
    height: 20px;
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
    border:none;
    background: rgba(243, 241, 236, 0);
    box-shadow: inset 3px 5px 25px rgba(165, 163, 160, 0),
      inset -10px -8px 15px rgba(240, 236, 236, 0);
      text-align: right;
      font-size: 14px;
}

#sectionParametre select[disabled] {
  opacity: 0.5; /* Réduire l'opacité pour donner une apparence de désactivation */
  cursor: not-allowed; /* Changer le curseur pour indiquer qu'il est non cliquable */
  /* Ajoutez d'autres styles selon vos besoins pour le bouton désactivé */
}

#sectionParametre input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 60px; /* Permet au range input d'occuper toute la largeur du conteneur */
  height: 3px; /* Hauteur de la barre coulissante */
  background-color: rgb(253, 152, 0);
  border-radius: 5px; /* Arrondir les coins de la barre */
}

#sectionParametre input[type="text"] {
  width: 141px;

}

#sectionParametre input[type="textarea"] {
  width: 150px;
  height: none;


}










#sectionParametre span {
  font-size: 15px;
  height: 20px;
  color: rgb(100, 100, 100);
  width: 35px;
  padding-left: 6px;
  /* border: 2px solid rgb(255, 0, 0); */
}


textarea{
  background: rgba(0, 0, 0, 0);
  border: none;
  width: 137px;
  resize: none;
}



td {
  min-width: 50px;
  padding: 8px;
  color: rgb(19, 59, 56);
  border: 1px solid rgba(254, 254, 254, 0.514);
  text-align: center;

}
