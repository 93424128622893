 /*Structure _____________________________________________________________________________________________________*/
 body {
   margin: 0px;
   padding: 0px;
   height: 100vh;
   width: 100vw;
   overflow: hidden;
   background: rgb(225, 224, 222);
 }

 #App {
   width: 100%;
   /* max-width: 1800px; */
   height: 100vh;
   margin: auto;
   margin-top: 0px;
   /* padding-bottom: 100px; */
   background-color: rgb(225, 224, 222);
   display: flex;
   justify-content: flex-start;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: center;
   /* border: 4px solid rgb(0, 0, 255); */
   overflow: hidden;
   /* background-image: url('https://i.pinimg.com/736x/f9/fb/2c/f9fb2c1327afdcce5747ca28fbf72058.jpg');
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover; */
 }

 #BodyApp {
   width: 100%;
   max-width: 1530px;
   height: 100%;
   display: flex;
   flex-direction: row-reverse;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-around;
   /* border: 2px solid rgb(13, 0, 255); */
   /* overflow-x: hidden; */
   overflow-y: scroll;
   margin-top: 0px;
   padding-bottom: 50px;

 }
 #BodyApp2 {
  width: 100%;
  /* max-width: 1530px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  /* border: 2px solid rgb(255, 0, 230); */
  /* overflow-x: hidden; */
  overflow-y: scroll;
  margin-top: 0px;
  padding-bottom: 50px;

}

 #divSection {
   flex: 1 1 510px;
   padding-top: 25px;
   padding-bottom: 20px;
   display: flex;
   flex-direction: column;
   /* flex-wrap: wrap; */
   align-items: center;
   justify-content: center;
   /* border: 1.5px solid rgb(255, 0, 0); */
   /* max-height: calc(90vh - 110px); */
 }

 #divSectionGraph {
  flex: 10000000000 1 560px;
  /* min-width: 510px; */
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  /* border: 1.5px solid rgb(13, 0, 255); */
  max-height: calc(90vh - 100px);
  /* max-width: 1360px; */
}
#divSectionParametre{
  flex: 1 1 490px;
  /* max-width: 1000px; */
  padding-top: 25px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  /* border: 1.5px solid rgb(9, 255, 0); */
  /* max-height: 40%;  */
}


#divConnexion{
  width: min(80%, 330px);
  display: flex;
  flex-direction:row;
  justify-content: center;
  /* border: 1.5px solid rgb(255, 0, 0); */
}

 section {
   width: min(90%, 800px);
   /* max-width: 600px; */
   height: 100%;
   display: flex;
   justify-content: flex-start;
   flex-direction: column;
   align-items: center;

   border-radius: 30px;
   background: rgb(225, 224, 222);
   box-shadow: 15px 15px 25px rgb(188, 186, 182),
     -10px -10px 25px rgb(254, 253, 250);
   padding-bottom: 5px;
   /* border: 2px solid rgb(52, 102, 29); */
   overflow-y: hidden;
    overflow-x: hidden;
   z-index: 0;
   
 }
 #sectionG{
  width: 90%;
  overflow-y: hidden;
  overflow-x: hidden;
  /* border: 2px solid rgb(255, 0, 0); */
 }
 


 #list{
  width: 95%;
  height: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
 align-items: center;
/* border: 2px solid rgb(255, 251, 0); */
overflow-y: scroll;
overflow-x: hidden;
 }





 h3 { /*Titre de section*/
   width: 80%;
   color: rgb(255, 255, 255);
   font-size: 20px;
   
   margin: 10px;
   margin-top: 15px;
   border-bottom: 1.5px solid rgb(253, 250, 244);
   padding-left: 30px;
   padding-bottom: 5px;
   text-shadow: 1px 1px 8px rgba(107, 103, 101, 0.851);
 }
 h4 {/*Titre d'aricle*/
  /* width: 90%; */
  color: rgb(28, 28, 28);
  font-size: 130%;

  text-align: center;
  padding: 2px;
  margin: 0px;
    /* border-top: 1px solid rgb(87, 87, 87); */

}

 p {
   color: rgb(115, 115, 115);
   font-family: Calibri;
   font-size: min(2.5vh, 19px);;
   text-align: justify;
   margin: 8%;
   margin-bottom: 20px;
   margin-top: 5px;
 }


 #MDPOublie {
   color: rgb(255, 132, 50);
   font-size: 15px;
   margin: auto;
   margin-bottom: 15px;
 }

 a {
   color: rgb(0, 0, 0);
   font-size: 18px;
   margin: auto;
   margin-bottom: 15px;
 }


 label {
  width: min(70%, 280px);
   color: rgb(36, 36, 36);
   font-size: 15px;
   padding: 6px;
   padding-top: 0px;
   padding-bottom: 0px;
   margin: 5px;
   text-align: start;
   /* border: 0.1px solid rgba(0, 0, 0, 0.301); */
 }

 input {
   width: min(70%, 280px);
   margin-bottom: 14px; 
   padding: 6px;
   font-size: 15px;
   color: rgb(19, 59, 56);
   border-radius: 10px;
   border:none;
   background: rgb(243, 241, 236);
   box-shadow: inset 3px 5px 25px rgb(165, 163, 160),
     inset -10px -8px 15px rgb(240, 236, 236);
 }
 select {
  width: min(76%, 284px);
  margin-bottom: 14px;
  padding: 6px;
  font-size: 15px;
  color: rgb(19, 59, 56);
  border-radius: 10px;
  border:none;
  background: rgb(243, 241, 236);
  box-shadow: inset 3px 5px 25px rgb(165, 163, 160),
    inset -10px -8px 15px rgb(240, 236, 236);
}



 article {
  flex : 1 0 150px;
  max-width: 200px;
  border-radius: 20px;
  /* width: 150px; */
  height:  190px;
  background: rgb(225, 224, 222);
  margin: 2%;
  /* border: 0.1px solid rgba(0, 0, 0, 0.301); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: rgb(19, 59, 56);
  font-size: 18px;
  padding: 5px;
  box-shadow: inset 6px 6px 12px rgba(29, 29, 29, 0.2),inset -10px -10px 12px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  overflow-x: hidden;
}



