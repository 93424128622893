#sage {
  width: min(50%, 250px);
  z-index: 2;
  /* border: 1.5px solid rgb(247, 0, 255); */
}
#sage2 {
  /* justify-self:end; */
  width: 80%;
  max-width: 500px;
  z-index: 2;
  margin-top: 5%;
  /* border: 1.5px solid rgb(247, 0, 255); */
  /* margin: 0px; */
}





#skate {
 /* padding-top: 5%; */
  /* margin-bottom: -10px; */
  width: 80%;
  max-width: 600px;
  /* border: 1.5px solid rgb(247, 0, 255); */
}

#graph {
  /* margin: 5%; */
  /* margin-top:15%; */
  /* margin-bottom:15%; */
  width: 95%;
  max-width: 700px;
  /* border: 1.5px solid rgb(247, 0, 255); */
}

#Voiture {
  /* margin: 5%; */
  margin-top:25px;
  margin-bottom:20px;
  width: 70%;
  max-width: 500px;
  /* border: 1.5px solid rgb(247, 0, 255); */
}




#NewProjet {
  width: 75%;
  height: 60%;
  margin: 0px;
  margin-top: 25px;
  /* border: 1.5px solid rgb(67, 65, 81); */
}

#NewComposant {
  width: 75%;
  height: 60%;
  margin: 0px;
  margin-top: 25px;
  /* border: 1.5px solid rgb(67, 65, 81); */
}

#NewArticle {
  width: 60%;
  height: 60%;
  margin: 0px;
  margin-top: 25px;
  /* border: 1.5px solid rgb(67, 65, 81); */
}